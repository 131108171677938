.delete-modal-button {
  background-color: #f1416c !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: none !important;
}

.delete-modal-button:hover {
  background-color: #ff9999 !important;
  color: #ffffff !important;
}

.cancel-delete-modal-button {
  background-color: #ffffff !important;
  color: #9ca3af !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #9ca3af !important;
}

.cancel-delete-modal-button:hover {
  background-color: #9ca3af !important;
  color: #ffffff !important;
}

.block-modal-button {
  background-color: #212121 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: none !important;
}

.block-modal-button:hover {
  background-color: #404040 !important;
  color: #ffffff !important;
}

.cancel-block-modal-button {
  background-color: #ffffff !important;
  color: #9ca3af !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #9ca3af !important;
}

.cancel-block-modal-button:hover {
  background-color: #9ca3af !important;
  color: #ffffff !important;
}