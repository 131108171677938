.add-driver-button {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
}

.add-driver-button:hover {
  background-color: #118f5e;
  color: #ffffff;
}

.onlineClass {
  background-color: #e8fff3;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #50cd89;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  width: auto;
}

.offlineClass {
  background-color: #f1f2f4;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #9ca3af;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: auto;
}

.onrideClass {
  background-color: #fffaeb;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #ffc000;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: auto;
}
